<template>
  <div>
    <b-modal
      id="change-status"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('ChangeStatus') + articleNumber"
      hide-footer
      @close="onCancel"
    >
      <b-form style="display: flex; flex-direction:column">
        <p style="margin-bottom:5px;">
          <span style="font-weight: bold;"> {{ $t("NewState") }} : {{ $t(comingStatus.newState) }}</span> <br> {{ $t('ChangeStatusOfItem') }}
        </p>
        <div
          v-if="showTeamSelect"
        >
          <b-form-group
            id="input-group-2"
            :label="$t('Team')"
          >
            <vue-select
              v-model="team"
              :options="getDropdownTeamName"
              :placeholder="$t('SelectTeam')"
              label="teamName"
              :reduce="(e) => e.teamNameId"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
          <p
            v-if="showTeamSelect == true && team == null && buttonSubmited == true"
            style="color:red;"
          >
            {{ $t('SelectPreferredTeam') }}
          </p>
          <div
            v-if="team == teamId"
            style="display:flex;flex-direction: column;gap:10px;"
          >
            <b-form-checkbox
              id="checkbox-1"
              v-model="isChecked"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
            >
              {{ $t('ClickToSelect') }}
            </b-form-checkbox>
            <b-form-group
              v-if="isChecked"
              :label="$t('Employees')"
            >
              <vue-select
                v-model="users"
                :options="getTeamOverview.users"
                :multiple="true"
                :placeholder="$t('SelectEmployee')"
                label="name"
                :reduce="(e) => e.userId"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </div>
        </div>
        <b-form-group
          v-if="showLocationSelect"
          id="input-group-1"
          :label="`${$t('Location')}:`"
          label-for="input-1"
        >
          <vue-select
            v-model="location"
            :options="getLocationsByType"
            :placeholder="$t('SelectLocation')"
            label="locationName"
            :reduce="(e) => e.locationId"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <p
          v-if="showLocationSelect == true && showDescriptionSelect == true && location == null && buttonSubmited == true"
          style="color:red;"
        >
          {{ $t('SelectLocation') }}
        </p>
        <div
          v-if="showIfChangeState"
        >
          <b-form-checkbox
            v-model="checkCompleted"
          >
            <span style="color:red;">
              {{ $t('ChangeStatusConfirmation') }}
            </span>
          </b-form-checkbox>
          <b-form-group
            v-if="showIfChangeState && checkCompleted"
            id="input-group-2"
            :label="$t('State')"
          >
            <vue-select
              v-model="form.state"
              :options="filteredOrderItemStates"
              label="name"
              :reduce="(e) => e.value"
              :placeholder="$t('Select')"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
          <p
            v-if="showIfChangeState == true && checkCompleted == true && form.state == null && buttonSubmited == true"
            style="color:red;"
          >
            {{ $t('SelectState') }}
          </p>
          <b-form-group
            v-if="showIfChangeState && checkCompleted"
            id="input-group-2"
            :label="$t('Team')"
          >
            <vue-select
              v-model="form.team"
              :options="getDropdownTeamName"
              :placeholder="$t('SelectTeam')"
              label="teamName"
              :reduce="(e) => e.teamNameId"
              aria-describedby="input-2-live-feedback"
            />
          </b-form-group>
          <p
            v-if="showIfChangeState == true && checkCompleted == true && form.team == null && buttonSubmited == true"
            style="color:red;"
          >
            {{ $t('SelectPreferredTeam') }}
          </p>
          <b-form-group
            v-if="showIfChangeState && checkCompleted"
            id="input-group-1"
            :label="$t('Description')"
            label-for="input-1"
          >
            <b-form-textarea
              id="input-1"
              v-model="form.notes"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
          <p
            v-if="showIfChangeState == true && checkCompleted == true && form.notes == '' && buttonSubmited == true"
            style="color:red;"
          >
            {{ $t('TypeReason') }}
          </p>
        </div>
        <div
          v-if="showIfChangeLocation"
        >
          <b-form-checkbox
            v-model="locationCompleted"
          >
            <span style="color:red;">
              {{ $t('ChangeWarehouse') }}
            </span>
          </b-form-checkbox>
          <b-form-group
            v-if="showIfChangeLocation && locationCompleted"
            id="input-group-1"
            :label="`${$t('Location')}:`"
            label-for="input-1"
          >
            <vue-select
              v-model="formLocation"
              :options="getLocationsByType"
              :placeholder="$t('SelectLocation')"
              label="locationName"
              :reduce="(e) => e.locationId"
              aria-describedby="input-1-live-feedback"
            />
          </b-form-group>
          <p
            v-if="showIfChangeLocation == true && locationCompleted == true && formLocation == null && buttonSubmited == true"
            style="color:red;"
          >
            {{ $t('SelectLocation') }}
          </p>
        </div>
        <b-form-group
          v-if="showDescriptionSelect"
          id="input-group-1"
          :label="$t('Description')"
          label-for="input-1"
        >
          <b-form-textarea
            id="input-1"
            v-model="notes"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <p
          v-if="showDescriptionSelect == true && showLocationSelect == false && notes == '' && buttonSubmited == true"
          style="color:red;"
        >
          {{ $t('TypeReason') }}
        </p>
        <p
          v-if="showLocationSelect == true && showDescriptionSelect == true && notes == '' && buttonSubmited == true"
          style="color:red;"
        >
          {{ $t('SelectLocation') }}
        </p>
        <b-form-group
          v-if="showDescriptionFailed"
          id="input-group-1"
          :label="$t('Description')"
          label-for="input-1"
        >
          <b-form-textarea
            id="input-1"
            v-model="description"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
        <p
          v-if="showDescriptionFailed == true && description == '' && buttonSubmited == true"
          style="color:red;"
        >
          {{ $t('TypeReason') }}
        </p>
        <div class="buttonsEverywhere">
          <b-button
            type="button"
            variant="none"
            class="buttonSubmit"
            @click="onSubmit"
          >
            {{ $t('Yes') }}
          </b-button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px;"
            @click="onCancel"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>

import { validationMixin } from 'vuelidate';
// import {
//   required,
// } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex'
// import { client } from '../../../domainConfig'


export default {
  mixins: [validationMixin],
  // eslint-disable-next-line vue/require-prop-types
  props: ['articleNumber', 'comingStatus', 'teamId'],
  data() {
    return {
      team: null,
      state: null,
      notes: '',
      description: '',
      isChecked: false,
      users: null,
      location: null,
      checkCompleted: false,
      locationCompleted: false,
      form: {
        team: null,
        state: null,
        notes: '',
      },
      formLocation: null,
      buttonSubmited: false,
    }
  },
  computed: {
    ...mapGetters(['getOrderItemStates', 'getTeamsNames', 'getDropdownTeamName', 'getLocationsByType', 'getTeamOverview']),
    filteredOrderItemStates() {
      return [
        {
          name: this.$t('Created'),
          value: 'Created',
        },
        {
          name: this.$t('Assigned'),
          value: 'Assigned',
        },
        {
          name: this.$t('Confirmed'),
          value: 'Confirmed',
        },
        {
          name: this.$t('InPattern'),
          value: 'InPattern',
        },
        {
          name: this.$t('InPatternQualityPassed'),
          value: 'InPatternQualityPassed',
        },
        {
          name: this.$t('InPatternQualityNotPassed'),
          value: 'InPatternQualityNotPassed',
        },
        {
          name: this.$t('InPatternCompleted'),
          value: 'InPatternCompleted',
        },
        {
          name: this.$t('InSewingAssigned'),
          value: 'InSewingAssigned',
        },
        {
          name: this.$t('InSewingConfirmed'),
          value: 'InSewingConfirmed',
        },
        {
          name: this.$t('InSewing'),
          value: 'InSewing',
        },
        {
          name: this.$t('InSewingQualityPassed'),
          value: 'InSewingQualityPassed',
        },
        {
          name: this.$t('InSewingQualityNotPassed'),
          value: 'InSewingQualityNotPassed',
        },
        {
          name: this.$t('InSewingCompleted'),
          value: 'InSewingCompleted',
        },
        {
          name: this.$t('InPackingAssigned'),
          value: 'InPackingAssigned',
        },
        {
          name: this.$t('InPackingConfirmed'),
          value: 'InPackingConfirmed',
        },
        {
          name: this.$t('InPacking'),
          value: 'InPacking',
        },
        {
          name: this.$t('ReadyToDeliver'),
          value: 'ReadyToDeliver',
        },
        {
          name: this.$t('InTransportAssigned'),
          value: 'InTransportAssigned',
        },
        {
          name: this.$t('InTransportConfirmed'),
          value: 'InTransportConfirmed',
        },
        {
          name: this.$t('InTransport'),
          value: 'InTransport',
        },
        {
          name: this.$t('InTransportCompleted'),
          value: 'InTransportCompleted',
        },
        {
          name: this.$t('MontageAssigned'),
          value: 'MontageAssigned',
        },
        {
          name: this.$t('MontageConfirmed'),
          value: 'MontageConfirmed',
        },
        {
          name: this.$t('Montage'),
          value: 'Montage',
        },
        {
          name: this.$t('MontageCompleted'),
          value: 'MontageCompleted',
        },
        {
          name: this.$t('MontagePartiallyCompleted'),
          value: 'MontagePartiallyCompleted',
        },
        {
          name: this.$t('InControlAssigned'),
          value: 'InControlAssigned',
        },
        {
          name: this.$t('InControlConfirmed'),
          value: 'InControlConfirmed',
        },
        {
          name: this.$t('InControlCheck'),
          value: 'InControlCheck',
        },
        {
          name: this.$t('QualityPassed'),
          value: 'QualityPassed',
        },
        {
          name: this.$t('Delivered'),
          value: 'Delivered',
        },
        {
          name: this.$t('ReadyToPickUp'),
          value: 'ReadyToPickUp',
        },
        {
          name: this.$t('StockNotAvailable'),
          value: 'StockNotAvailable',
        },
        {
          name: this.$t('QualityCheckNotPassed'),
          value: 'QualityCheckNotPassed',
        },
        {
          name: this.$t('Cancelled'),
          value: 'Cancelled',
        },
      ];
    },
    showTeamSelect() {
      const validStates = ['InSewingAssigned', 'InPackingAssigned', 'InTransportAssigned', 'MontageAssigned', 'InControlAssigned'];
      return validStates.includes(this.comingStatus.newState);
    },
    showLocationSelect() {
      const validStates = ['ReadyToDeliver', 'ReadyToPickUp'];
      return validStates.includes(this.comingStatus.newState);
    },
    showDescriptionSelect() {
      const validStates = ['InPatternQualityNotPassed', 'ReadyToDeliver', 'ReadyToPickUp', 'InSewingQualityNotPassed', 'InTransportQualityNotPassed', 'MontagePartiallyCompleted', 'QualityCheckNotPassed'];
      return validStates.includes(this.comingStatus.newState);
    },
    showIfChangeState() {
      const validStates = ['InSewingConfirmed', 'InPackingConfirmed', 'InTransportConfirmed', 'MontageConfirmed'];
      return validStates.includes(this.comingStatus.newState);
    },
    showIfChangeLocation() {
      const validStates = ['InPatternCompleted', 'InSewingCompleted', 'InTransportCompleted', 'InControlConfirmed'];
      return validStates.includes(this.comingStatus.newState);
    },
    showDescriptionFailed() {
      const validStates = ['QualityCheckNotPassed'];
      return validStates.includes(this.comingStatus.newState);
    },
  },
  watch: {
    checkCompleted(value) {
      if (value == false) {
        this.form.team = null
        this.form.state = null
        this.form.notes = ''
      }
    },
    locationCompleted(value) {
      if (value == false) {
        this.formLocation = null
      }
    },
    team(value) {
      this.users = null
      this.isChecked = false
      if (value != null) {
        this.teamByTeamNameId(value)
      }
    },
    isChecked(value, newValue) {
      if (value != newValue) {
        this.users = null
      }
    },
  },
  methods: {
    ...mapActions([
      'teamByTeamNameId',
    ]),
    onSubmit() {
      this.buttonSubmited = true

      let objekti

      if (this.showTeamSelect == true && this.team == null && this.buttonSubmited == true) {
        return;
      }
      // if (this.showLocationSelect == true && this.showDescriptionSelect == true && this.location == null && this.notes == '' && this.buttonSubmited == true) {
      //   return;
      // }
      if (this.showDescriptionSelect == true && this.showLocationSelect == false && this.notes == '' && this.buttonSubmited == true) {
        return;
      }
      if (this.showIfChangeState == true && this.checkCompleted == true && this.form.state == null && this.form.team == null && this.form.notes == '' && this.buttonSubmited == true) {
        return;
      }
      if (this.showIfChangeLocation == true && this.locationCompleted == true && this.formLocation == null && this.buttonSubmited == true) {
        return;
      }
      if (this.showDescriptionFailed == true && this.description == '' && this.buttonSubmited == true) {
        return;
      }

      if (this.showTeamSelect) {
        objekti = {
          ...this.comingStatus,
          newTeamNameId: this.team,
          processingUserIds: this.users,
          qualityFailed: false,
          forceMethod: this.checkCompleted,
        }
      } else if (this.showLocationSelect && this.showDescriptionSelect) {
        objekti = {
          ...this.comingStatus,
          locationId: this.location,
          notes: this.notes,
          qualityFailed: false,
          forceMethod: this.checkCompleted,
        }
      } else if (this.showDescriptionSelect) {
        objekti = {
          ...this.comingStatus,
          notes: this.notes,
          qualityFailed: false,
          forceMethod: this.checkCompleted,
        }
      } else if (this.showIfChangeState) {
        if (this.form.state != null || this.form.team != null || this.form.notes != '') {
          objekti = {
            orderNumber: this.comingStatus.orderNumber,
            windowItemId: this.comingStatus.windowItemId,
            newState: this.form.state,
            newTeamNameId: this.form.team,
            notes: this.form.notes,
            forceMethod: this.checkCompleted,
            qualityFailed: false,
          }
        } else {
          objekti = {
            ...this.comingStatus,
            forceMethod: this.checkCompleted,
            qualityFailed: false,
          }
        }
      } else if (this.showIfChangeLocation) {
        if (this.formLocation != null) {
          objekti = {
            ...this.comingStatus,
            locationId: this.formLocation,
            qualityFailed: false,
            forceMethod: this.checkCompleted,
          }
        } else {
          objekti = {
            ...this.comingStatus,
            qualityFailed: false,
            forceMethod: this.checkCompleted,
          }
        }
      } else if (this.showDescriptionFailed) {
        objekti = {
          ...this.comingStatus,
          description: this.description,
          qualityFailed: true,
          forceMethod: this.checkCompleted,
        }
      } else {
        objekti = {
          ...this.comingStatus,
          qualityFailed: false,
          forceMethod: this.checkCompleted,
        }
      }
      this.$emit('statusOfModal', objekti)
      this.$refs.modal.hide();
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      this.resetForm()
    },
    resetForm() {
      this.team = null
      this.state = null
      this.notes = ''
      this.form.team = null
      this.form.state = null
      this.form.notes = ''
      this.description = ''
      this.isChecked = false
      this.users = null
      this.location = null
      this.checkCompleted = false
      this.formLocation = null
      this.locationCompleted = false
      this.buttonSubmited = false
    },
  },
}
</script>

<style lang="scss" scoped>
  form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .flex-form {
    display: flex;
    flex-direction: column;
  }
  .buttonat{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
</style>
